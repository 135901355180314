.icon {
  width: 50px;
  height: 50px;
}

.videoContainer {
  grid-template: "container";
}

.video,
.poster {
  // overlay the video on top of the poster
  grid-area: container;

  width: 100%;
  object-fit: cover;
}

.poster {
  // hide the poster behind the video
  position: absolute;
  z-index: -1;
}

.video {
  height: 100%;
}
