@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  position: relative;
  overflow: hidden;
  background: $color-black;
}

.innerContainer {
  max-width: $page-content-lg-max-width;
  margin: 0 auto;

  @include md-min {
    display: flex;
  }

  @include lg-min {
    padding: 0 $space-lg;
  }
}

.itemContainer {
  position: relative;
  background: $color-black;
  padding-bottom: $space-lg;

  @include md-min {
    flex-basis: 50%;

    &::after {
      content: " ";
      display: block;
      position: absolute;
      width: 1px;
      height: 100%;
      top: 0;
      right: 0;
      background-color: $color-white;
      z-index: 1;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}

.itemInnerContainer {
  height: 100vh;
  position: relative;

  &::before,
  &::after {
    content: " ";
    display: block;
    position: absolute;
    height: 1px;
    left: -50%;
    right: -50%;
    background-color: $color-white;
    z-index: 1;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  @include md-min {
    height: 700px;
  }

  @include xl-min {
    height: 800px;
  }
}

.imageWrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.image {
  position: relative;
  top: -160px;

  @include sm-max {
    min-width: 500px;
  }

  @include lg-max {
    min-width: 600px;
  }
}

.textWrapper {
  position: absolute;
  z-index: 1;
  padding: $space-lg;
  background: rgba(24, 24, 24, 0.8);
  backdrop-filter: blur(3px);
  color: $color-white;

  @include sm-max {
    bottom: 0;
  }

  @include md-min {
    top: 170px;
  }

  @include lg-min {
    top: 270px;
  }

  @include xl-min {
    top: 370px;
    margin: 0 $space-lg;
  }
}

.title {
  font-size: $font-size-lg;
  font-weight: $font-weight-light;
  line-height: 1;
  margin: 0 0 $space-sm;
  padding-bottom: $space-sm;
  position: relative;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    height: 1px;
    left: -50%;
    right: -50%;
    background-color: $color-white;
    z-index: 1;
    bottom: 0;
  }

  @include md-min {
    font-size: $font-size-xl;
    font-weight: $font-weight-base;
  }

  @include xl-min {
    font-size: #{$font-size-xl + 2px};
  }
}

.body {
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
  line-height: 1.222;
  letter-spacing: $letter-spacing-small-text;

  p {
    margin: $space-sm 0;
  }

  @include xl-min {
    font-size: $font-size-md;
  }
}

.link {
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
  line-height: 1.222;
  letter-spacing: $letter-spacing-small-text;

  @include xl-min {
    font-size: $font-size-md;
  }
}
