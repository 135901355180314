@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

.container {
  padding: 7%;
  transition: transform 0.3s;
  min-height: 50%;

  &:hover {
    position: relative;
    z-index: 1;
    transform: scale(1.05);
    border: solid 1px $semi-transparent-border;
  }

  @include sm-max {
    max-width: 50%;
  }
}

.headline {
  text-align: center;
  text-transform: uppercase;
  margin: $space-xs auto;
}

.image {
  width: 140px;
  margin: 0 auto;

  @include lg-min {
    width: 240px;
  }
}
