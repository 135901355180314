@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.root {
  border-top: 1px solid $color-secondary-200;
  padding: 0;

  @include md-min {
    overflow: hidden;
    flex-wrap: nowrap;

    > .twoItems:first-child {
      position: relative;

      // Avoid container width discrepancy between the two items
      // by using psuedo element to create right border.
      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;

        top: 0;
        right: 0;
      }
    }
  }
}

.twoItems {
  --num-children: 2;
}

.threeItems {
  --num-children: 3;
}

.fourItems {
  --num-children: 4;
}

.twoItems,
.threeItems,
.fourItems {
  margin: 0 auto;
  width: 100%;
  padding-bottom: 0;

  @include md-min {
    margin: 0 auto 0 0;
    max-width: calc($page-content-lg-max-width / var(--num-children));
    padding-bottom: $space-lg;

    &:first-child {
      margin: 0 0 0 auto;
    }
  }

  > .embeddedContainer {
    margin: 0;
    padding-bottom: 0;
  }
}
