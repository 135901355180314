@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

.root {
  display: flex;
  flex-wrap: wrap;
  padding: $space-md 0;
  margin-bottom: $space-md;
  justify-content: center;

  @include md-min {
    justify-content: flex-start;
    width: var(--width);
  }
}

.embeddedContainer {
  margin: 0 $space-xxxs;
  margin-top: calc(#{$space-xxxs} * 2);
}

.singleItem {
  width: 90%;
  margin: 0 auto;

  @include md-min {
    width: 80%;
  }
}

.twoItems {
  margin: 0 auto;
  width: 100%;
  padding-bottom: calc(#{$space-xxs} * 2);

  @include md-min {
    width: 50%;
  }

  > .embeddedContainer {
    padding-bottom: calc(#{$space-xxs} * 2);
  }
}

.threeItems {
  width: 90%;
  margin: 0 auto;

  @include md-min {
    width: 33%;
  }
}

.fourItems {
  width: 50%;

  @include md-min {
    width: 25%;
  }
}

.fiveItems {
  width: 90%;

  @include md-min {
    width: 20%;
  }
}

.sixItems {
  width: 50%;

  @include md-min {
    width: 16%;
  }
}
